<template>
  <FocusTrap>
    <div id="mycard" >

    <div class="card-title bg-success-800 panel-title"  style="padding-left: 15px;padding-top: 3px; ">
      <h4>
        <span class="font-weight-semibold"> <i class="icon-cash icon-2x "></i> Sales Receipt</span> Information
      </h4>
    </div>



    <div  id="rcpt-window" class="card-body" style="padding-top: 0px;padding-bottom: 0px;">

      <div >
        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Received By</label>
              <select id="cmbledger" class="form-control select" autofocus=""  v-if="voucher" v-model="voucher.receviedby.code" >
                <option v-for="ledger in ledgers" v-bind:value="ledger.code">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Instrument No</label>
              <input type="text" class="form-control" maxlength="30" v-if="voucher" v-model="voucher.instrument">
            </div>
          </div>

          <div class="col-md-3">

          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label  font-weight-semibold">Voucher Date</label>
              <input type="date" class="form-control text-right" v-if="voucher" v-model="voucher.rcpt_date" >
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Received Amount (&#8377;)</label>
              <input type="number" class="form-control text-right" :max="invoice.net_amount" v-if="voucher" v-model="voucher.amount"  >
            </div>
          </div>

          <div class="col-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">TDS Amount</label>
              <input type="number" class="form-control text-right" :max="invoice.net_amount" min="0" v-if="voucher" v-model="voucher.tds_amt" @change="tds_changes" :disabled="invoice.type  == 406" >
            </div>
          </div>
          <div class="col-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">CBC</label>
              <input type="number" class="form-control text-right" :max="invoice.net_amount" min="0" v-if="voucher" v-model="voucher.bank_com" :disabled="invoice.type  !== 406">
            </div>
          </div>
          <div class="col-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Difference</label>
              <input type="number" class="form-control text-right" :max="invoice.net_amount" min="0" v-if="voucher" v-model="voucher.dif_amt" :disabled="invoice.type  !== 406">
            </div>
          </div>

          <div class="col-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Exchange @</label>
              <input type="number" class="form-control text-right" min="0" v-if="voucher" v-model="voucher.ex_rate" :disabled="invoice.type  !== 406">
            </div>
          </div>
          <div class="col">

          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Remarks</label>
              <textarea class="form-control" v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>
          <div class="col">

          </div>
          <div class="col text-right" style="vertical-align: bottom;vertical-align: text-bottom;">
            <button class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="saveDocument">Save</button>
          </div>
        </div>
      </div>

      <div class="card card-collapsed" style="background-color: whitesmoke;">
        <div class="card-header header-elements-inline">
          <h5 class="card-title">Sale Invoice</h5>
          <div class="header-elements">
            <div class="list-icons">
              <a class="list-icons-item rotate-180" data-action="collapse"></a>
              <a class="list-icons-item" data-action="reload"></a>

            </div>
          </div>
        </div>


        <div class="card-body">


          <div class="row">

            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Buyer</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invoice.ledger.name" readonly>
              </div>
            </div>

            <div class="col-md-1">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Type</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invoice.type" readonly>
              </div>
            </div>

            <div class="col-md-5">

            </div>

            <div class="col-md-1">
              <div class="form-group form-group-material">
                <label class=" control-label font-weight-semibold">Invoice No</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invoice.ref_no" readonly>
              </div>
            </div>


            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label  font-weight-semibold">Invoice Date</label>
                <input type="date" class="form-control" v-if="invoice" v-model="invoice.ref_date" readonly >
              </div>
            </div>

          </div>

          <div class="row">
            <!--  Detail Table -->
            <div class="table-responsive">

              <table id="mytable" class="table table-no-bordered">
                <thead style="background-color: lightgrey">
                <tr>
                  <th style="width:50px;">S.No</th>
                  <th >Item</th>
                  <th style="width:100px; text-align: right;">GST%</th>
                  <th style="width:100px; text-align: right;">Rate</th>
                  <th style="width:75px; text-align: right;">Qty</th>
                  <th style="width:100px; text-align: right;">Taxable</th>
                  <th style="width:100px; text-align: right;">IGST</th>
                  <th style="width:100px; text-align: right;">CGST</th>
                  <th style="width:100px; text-align: right;">SGST</th>
                  <th style="width:150px; text-align: right;">Net Amount</th>
                </tr>

                </thead>
                <tbody v-for="(detail,index) in invoice.list" >
                <tr style="padding: 0px; height: 40px;">
                  <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

                  <td style="padding: 0px;">
                    {{ detail.item.name}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{detail.gst_rate}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.rate)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.qty)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.taxable_amt)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.igst_amt)}}
                  </td>
                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.cgst_amt)}}
                  </td>
                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.sgst_amt)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.net_amt)}}
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" ><span >{{ rowQtyTotal }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowTaxableTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowIgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowCgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowSgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowAmountTotal) }}</span></td>


                </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Narration</label>
                <textarea class="form-control" v-if="invoice" v-model="invoice.remarks" readonly> </textarea>
              </div>
            </div>


            <div class="col-md-6">
              <h3 style="font-size: 48px;color: blue">
                &#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(invoice.round_off) ) }}</span>
              </h3>
            </div>

            <div class="col-md-3 text-right">
              <div class="form-group row">
                <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
                <div class="col-md-8">
                  <input type="number" class="form-control text-right" v-if="invoice" v-model="invoice.round_off" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>



  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'SaleReceipt',
    store,
    props: {
      invoice: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}')
      },
      myreceipt:{
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"type":0,"sale_code":0,"ibr":0,"status":0,"finyear":0,"inv_no":"","customer":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","opening_balance":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"receviedby":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"instrument_type":0,"instrument":"","rcpt_date":"0001-01-01","amount":0,"bank_com":0,"ex_rate":0,"dif_amt":0,"tds_amt":0,"remarks":"","acc_cd":0}')
      }
    },
    data(){
      return {
        rowQtyTotal:0.0,
        rowAmountTotal: 0.0,
        rowIgstTotal:0.0,
        rowCgstTotal:0.0,
        rowSgstTotal:0.0,
        rowTaxableTotal:0.0,
        ledgers:[],
        voucher: JSON.parse('{"id":"","code":0,"type":0,"sale_code":0,"ibr":0,"status":0,"finyear":0,"inv_no":"","customer":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","opening_balance":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"receviedby":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"instrument_type":0,"instrument":"","rcpt_date":"0001-01-01","amount":0,"bank_com":0,"ex_rate":0,"dif_amt":0,"tds_amt":0,"remarks":"","acc_cd":0}'),
        invoice_amt :0.0,
      }
    },
    created(){
      this.$data.voucher.rcpt_date = moment().format('YYYY-MM-DD');
    },
    beforeMount(){
      let self = this;
      this.invoice.list.forEach(function (det) {
        self.rowQtyTotal += det.qty;
        self.rowIgstTotal += det.igst_amt;
        self.rowCgstTotal += det.cgst_amt;
        self.rowSgstTotal += det.sgst_amt;
        self.rowTaxableTotal += det.taxable_amt;
        self.rowAmountTotal += det.net_amt;
      });

      if(this.invoice.status > 0){
        self.fetch_voucher(this.invoice.id);
      }
      this.voucher.amount = this.invoice.net_amount;
      this.voucher = this.myreceipt;



    },
    mounted(){

      $('#divinvoice').slideUp();
      this.loadLedgers();

      // Collapse card
      var _cardActionCollapse = function() {
        var $cardCollapsedClass = $('.card-collapsed');

        // Hide if collapsed by default
        $cardCollapsedClass.children('.card-header').nextAll().hide();

        // Rotate icon if collapsed by default
        $cardCollapsedClass.find('[data-action=collapse]').addClass('rotate-180');

        // Collapse on click
        $('.card [data-action=collapse]:not(.disabled)').on('click', function (e) {
          var $target = $(this),
            slidingSpeed = 150;

          e.preventDefault();
          $target.parents('.card').toggleClass('card-collapsed');
          $target.toggleClass('rotate-180');
          $target.closest('.card').children('.card-header').nextAll().slideToggle(slidingSpeed);
        });
      };

     // _cardActionCollapse();

      $('#cmbledger').focus();
    },
    methods:{

      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      tds_changes(){
        this.$data.voucher.amount = this.$props.invoice.net_amount - this.$data.voucher.tds_amt;
      },
      fetch_voucher(id){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/purchasepayment/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.voucher = resp.data;
            self.$data.voucher.pay_date = moment(self.$data.voucher.pay_date).format('YYYY-MM-DD');
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        }).finally(function () {
          $('#mycard').unblock();
        });


      },
      loadLedgers(){
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/groups/12,22,23/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
      saveDocument(){
        const self = this;

        if( parseFloat(self.$data.voucher.amount) < 0 ){
          alert("Invalid Received Amount");
          return
        }

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, credit this!',
          cancelButtonText: 'No, cancel!',
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          buttonsStyling: false
        }).then(function(result) {
          if(result.value) {

            if (self.$data.voucher.code == 1){
              self.$data.voucher.code = 0;
            }

            self.$data.voucher.amount = parseFloat(self.$data.voucher.amount);
            self.$data.voucher.tds_amt = parseFloat(self.$data.voucher.tds_amt);
            self.$data.voucher.customer.code = self.$props.invoice.ledger.code;
            self.$data.voucher.ibr = parseInt(localStorage.getItem('branch_code'));

            if(self.$data.voucher.receviedby.code < 1) {
              alert('Invalid Received Ledger');
              return;
            }else if(self.$data.voucher.customer.code < 1){
                alert('Invalid Party Ledger');
                return;
            }else if( self.$data.voucher.amount  <= 0  ){
              alert('Invaild Amount');
              return;
            }

            let myoucher =  JSON.parse(JSON.stringify(self.$data.voucher))

            myoucher.rcpt_date = moment(self.$data.voucher.rcpt_date).format('YYYY-MM-DD') + 'T00:00:00Z';
            myoucher.sale_code = self.$props.invoice.code;
            myoucher.finyear = store.state.user.finyear;

            myoucher.amount = parseFloat(self.$data.voucher.amount);
            myoucher.bank_com = parseFloat(self.$data.voucher.bank_com);
            myoucher.ex_rate = parseFloat(self.$data.voucher.ex_rate);
            myoucher.dif_amt = parseFloat(self.$data.voucher.dif_amt);
            myoucher.tds_amt = parseFloat(self.$data.voucher.tds_amt);

            const requestOptions = {
              method:  (myoucher.code == 0 ? 'POST' : 'PUT'),
              mode:'cors',
              headers: userService.authHeader(),
              body: JSON.stringify(myoucher)
            };



            $('#rcpt-window').block({
              message: '<i class="icon-spinner2 spinner"></i>',
              overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait',
                'box-shadow': '0 0 0 1px #ddd'
              },
              css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
              }
            });

            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/salereceipt/`,requestOptions).then(userService.handleResponse).then(function (resp) {
              if (resp.accepted){
                swal({title: 'Great', type: 'success', text: resp.message, timer: 3000 });
                self.$emit('receipt_updated');
              }else{
                swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
              }
            }).catch(function (err) {
              swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
            }).finally(function () {
              $('#rcpt-window').unblock();
            });
          }
        });




      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
