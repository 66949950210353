<template>
  <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Sale Receipt List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="table-responsive">
      <table id="sale_list_table"
             class="table table-columned table-hover"
             data-search="false"
             data-pagination="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="status" data-class="d-none">Status</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="rcpt_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
          <th data-field="rcpt_no" data-width="100" data-sortable="true">Rcpt No</th>
          <th data-field="customer.name" data-sortable="true">Party</th>
          <th data-field="inv_no" data-width="100" data-sortable="true">Inv No</th>
          <th data-field="receviedby.name" data-sortable="true">Received By</th>
          <th data-field="amount" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Amount</th>
          <th data-field="tds_amt" data-width="70" data-align="right" data-formatter="indianFormat" data-sortable="true">TDS</th>
          <th data-field="remarks" data-sortable="true">Remarks</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-file-eye2" ></i>Preview</a>

        <div class="dropdown-divider"></div>
        <!--<a href="#" class="dropdown-item" ><i class="icon-cash4" style="color: darkgreen"></i>Payment</a>-->
        <!--<a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify Purchase</a>-->
        <a href="#" class="dropdown-item" > <i class="icon-pencil5" ></i>Modify Payment</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
      </div>
    </div>
    <!-- End of Context Menu -->



    <div class="modal" id="receipt_modal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <ReceiptForm v-if="invoice.code > 1" v-bind:invoice="invoice" v-on:sale_updated="onReceiptUpdate" > </ReceiptForm>
          </div>


        </div>
      </div>
    </div>

    <div class="modal" id="previewmodal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <PurchaseInvoicePreview v-if="invoice.code > 1" v-bind:invoice="invoice" > </PurchaseInvoicePreview>
          </div>


        </div>
      </div>
    </div>


  </div>

</template>

<script>
  import InvoiceForm from '@/views/tms/vouchers/invoice/InvoiceForm.vue'
  import ReceiptForm from '@/views/tms/vouchers/saleinvoice/SaleReceipt.vue'
  import PurchaseInvoicePreview from '@/views/tms/vouchers/invoice/PurchaseInvoicePreview.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'SaleReceiptView',
    components: {
      InvoiceForm,
      ReceiptForm,
      PurchaseInvoicePreview,
      FabButton
    },
    store,
    data () {
      return {
        mytable: {},
        invoice: JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":0,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
        finyear:  2018,
        series: 302,
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#sale_list_table');
      this.$data.mytable.bootstrapTable();
      $('#sale_list_table').on('post-body.bs.table', function (e) {
        $('[data-toggle="popover"]').popover();
      });

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          var id = $(row.children('*')[0]).text();
          var status =  parseInt($(row.children('*')[1]).text());


          if ($(e.target).text() === 'Modify Receipt') {
            if(status >= 0) {
              self.modifyReceipt(id, status);
            }
          }else if ($(e.target).text() === 'Cancel') {
            if(status === 0) {
              self.removeDocument(id, status);
            }
          }else if ($(e.target).text() === 'Payment') {
            self.invoicePayment(id, status);
          }else if ($(e.target).text() == 'Preview'){
            self.invoicePreview(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#sale_list_table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadData();


    },
    methods: {
      closeModal() {

      },
      beforeOpen(){

      },
      beforeClose(){
        // this.$data.invoice = JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
      },
      showModal () {

      },
      onReceiptUpdate(){
        this.loadData();
        $('#receipt_modal').modal('hide');
      },

      modifyReceipt (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        self.$data.invoice = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/salereceipt/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
            self.$data.invoice.status = 1;

            $('#receipt_modal').modal('show');

          } else {
            swal({ title: "Oops!", text: resp.message, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        });


      },
      removeDocument (id) {
        let self = this;

        if(true){
          return;
        }
        self.$data.invoice.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.invoice)
        };

        swal({
          title: 'What is the reason for cancel this transaction?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function(value) {
            return !value && 'You need to write something!'
          }
        }).then(function(result) {
          if(result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/salereceipt/${id}/?remarks=${result.value}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              // self.$modal.hide('receipt-window');
              if(resp.accepted){
                self.loadData();
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                });
              }else {
                swal({ title: "Oops", text: resp.message, type: "info" });
              }

            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });


      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#txtsearch').val('');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);


        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/salereceipt/finyear/${store.state.user.finyear}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( !_.isNull(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
          $(self.$data.mytable).bootstrapTable('resetView');
        });

      }
    }

  }
</script>

<style scoped>

  .modal-content {
    padding: 0px;
  }
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
